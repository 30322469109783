<template>
  <div>
    <!-- <a
      class="btn btn-primary btn-action mr-1"
      @click="getUserChild1"
      :disabled="dataDelete.length > 0"
      v-if="item.objChild > 0"
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <i class="fas fa-users"></i>
          </span>
      </template>
      <span>Drill Down Users</span>
    </v-tooltip>
      
    </a>
    <a class="btn btn-primary disabled btn-action mr-1" disabled v-else-if=" userp.role_id == '61829dc7e64b1b17688326d5' ||
        userp.role_id == '61829dd1e64b1b17688326df' ||
        userp.role_id == '61829de6e64b1b17688326f3' ||
        userp.role_id == '6424071f50b77c6cf890ab0d'" hidden>
      <i class="fas fa-users"></i>
    </a>
    <a class="btn btn-primary disabled btn-action mr-1" disabled v-else>
      <i class="fas fa-users"></i>
    </a> -->

    <a
      class="btn btn-info btn-action mr-1"
      @click="onDialogChangePassword"
      :disabled="dataDelete.length > 0"
      v-if="checkUserPermission('CHANGE-PASSWORD')"
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <i class="fas fa-user-cog"></i>
          </span>
      </template>
      <span>Change Password</span>
    </v-tooltip>
      
    </a>

    <a
      class="btn btn-warning btn-action mr-1"
      @click="updateUser"
      :disabled="dataDelete.length > 0"
      v-if="checkUserPermission('EDIT')"
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <i class="far fa-edit"></i>
          </span>
      </template>
      <span>Edit User</span>
    </v-tooltip>
      
    </a>

    <a
      class="btn btn-success btn-action mr-1"
      @click="upConfigUser"
      :disabled="dataDelete.length > 0"
      v-if="
        userp.role_id == '61829dc7e64b1b17688326d5' ||
        userp.role_id == '61829dd1e64b1b17688326df' ||
        userp.role_id == '61829de6e64b1b17688326f3' ||
        userp.role_id == '6424071f50b77c6cf890ab0d' ||
        userp.role_id == '6191dc644825e6f57ffa231f' ||
        userp.role_id == '6191dce94825e65937fa2340' ||
        userp.role_id == '6424080f50b77c6cf890ab11' ||
        userp.role_id == '6472bfce1ba4b8a1604940c2'
      "
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <i class="fas fa-cogs"></i>
          </span>
      </template>
      <span>Limmit Betting</span>
    </v-tooltip>
    </a>

    <a
      class="btn btn-primary btn-action mr-1"
      @click="getAllLimitBetting"
      :disabled="dataDelete.length > 0"
      v-if="
        checkUserPermission('EDIT') &&
        userp.role_id != '611532b19c6e77ad0fd50c1c' &&
        userp.role_id != '61829dc7e64b1b17688326d5' &&
        userp.role_id != '61829dd1e64b1b17688326df' &&
        userp.role_id != '61829de6e64b1b17688326f3' &&
        userp.role_id != '61bf5eb0c73d7eea8634fbf4' &&
        userp.role_id != '6424071f50b77c6cf890ab0d' &&
        userp.role_id != '6191dc644825e6f57ffa231f' &&
        userp.role_id != '6191dce94825e65937fa2340' &&
        userp.role_id != '6424080f50b77c6cf890ab11' &&
        userp.role_id != '6472bfce1ba4b8a1604940c2'
      "
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <i class="fas fa-cogs"></i>
          </span>
      </template>
      <span>Limmit Betting</span>
    </v-tooltip>
    </a>

    <!-- <a
      class="btn btn-info btn-action mr-1"
      @click="addUpdateBankUser"
      :disabled="dataDelete.length > 0"
      v-if="
        userp.role_id == '61829dc7e64b1b17688326d5' ||
        userp.role_id == '61829dd1e64b1b17688326df' ||
        userp.role_id == '61829de6e64b1b17688326f3' ||
        userp.role_id == '6424071f50b77c6cf890ab0d' 
      "
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <i class="fa fa-university"></i>
          </span>
      </template>
      <span>Bank Account</span>
    </v-tooltip>
    </a> -->

    <a
      class="btn btn-warning btn-action mr-1"
      @click="upConfigSlotLimit"
      :disabled="dataDelete.length > 0"
      v-if="
        userp.role_id == '61829dc7e64b1b17688326d5' ||
        userp.role_id == '61829dd1e64b1b17688326df' ||
        userp.role_id == '61829de6e64b1b17688326f3' ||
        userp.role_id == '6424071f50b77c6cf890ab0d' ||
        userp.role_id == '6191dc644825e6f57ffa231f' ||
        userp.role_id == '6191dce94825e65937fa2340' ||
        userp.role_id == '6424080f50b77c6cf890ab11' ||
        userp.role_id == '6472bfce1ba4b8a1604940c2'
      "
    >
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
          <span v-bind="attrs" v-on="on">
            <i class="fas fa-cogs"></i>
          </span>
      </template>
      <span>Limmit Slot</span>
    </v-tooltip>
    </a>
      
    <v-menu offset-y class="mx-0 px-0 " :disabled="dataDelete.length > 0" 
      v-if="checkUserPermission('EDIT') && userp.role_id == '611532b19c6e77ad0fd50c1c' ">
    <template v-slot:activator="{ on, attrs }">
        <v-btn text dark v-bind="attrs" v-on="on" class="btn btn-info btn-action mr-1" style="min-width:0px" >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                  <i class="fas fa-cogs"></i>
                </span>
            </template>
            <span>Limmit Betting</span>
          </v-tooltip>
          
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(item, index) in items"
          :key="index"
          :value="item.value"
          @click="updateCompanyLimitBetting(item.value)">
          <v-list-item-title >{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
      </v-menu>

    <!-- <a
      style="color: white; font-weight: 400"
      :class="item.enable ? 'btn btn-success' : 'btn btn-danger'"
      @click="_openDialogSuspend(item)"
      :disabled="userp && !userp.enable ? true : false"
    >
      <i :class="item.enable ? 'fas fa-check' : 'fas fa-times'"></i>
    </a> -->

    <dialog-change-password
      :darkmode="darkmode"
      :dialog="dialogChangePassword"
      :is-validate="validPassword"
      :user="user"
      :loading="loading"
      v-on:submit="onChangePassword"
      v-on:onCloseDialog="closeDialogChangePassword"
    />

    <config-user-data
      v-if="
        userp.role_id == '61829dc7e64b1b17688326d5' ||
        userp.role_id == '61829dd1e64b1b17688326df' ||
        userp.role_id == '61829de6e64b1b17688326f3' ||
        userp.role_id == '6424071f50b77c6cf890ab0d' ||
        userp.role_id == '6191dc644825e6f57ffa231f' ||
        userp.role_id == '6191dce94825e65937fa2340' ||
        userp.role_id == '6424080f50b77c6cf890ab11' ||
        userp.role_id == '6472bfce1ba4b8a1604940c2'
      "
      :darkmode="darkmode"
      :dialog="dialogConfigUserData"
      :is-validate="validPassword"
      :dataConfig="configUserData"
      :loading="loading"
      :limitConfig="limitConfig"
      :user="item"
      v-on:submit="onChangeConfigUser"
      v-on:onCloseDialog="closeConfigUserDialog"
      v-on:changeTab="onChangeTab"
    />

    <config-slot-limit-data
      v-if="
        userp.role_id == '61829dc7e64b1b17688326d5' ||
        userp.role_id == '61829dd1e64b1b17688326df' ||
        userp.role_id == '61829de6e64b1b17688326f3' ||
        userp.role_id == '6424071f50b77c6cf890ab0d' ||
        userp.role_id == '6191dc644825e6f57ffa231f' ||
        userp.role_id == '6191dce94825e65937fa2340' ||
        userp.role_id == '6424080f50b77c6cf890ab11' ||
        userp.role_id == '6472bfce1ba4b8a1604940c2'
      "
      :darkmode="darkmode"
      :dialog="dialogConfigSlotLimitData"
      :dataConfig="configSlotLimitData"
      :loading="loading"
      :user="item"
      v-on:submit="onChangeConfigSlotLimit"
      v-on:onCloseDialog="closeConfigSlotLimitDialog"
    />

    <config-high-level-user
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUser"
      :is-validate="validPassword"
      :dataConfig="limitHighLevelUser"
      :limitConfig="limitConfig"
      :loading="loading"
      :userp="userp"
      :user="item"
      v-on:submit="onChangeConfigHighLevelUser"
      v-on:onCloseDialog="closeConfigHighLevelUserDialog"
      v-on:changeTab="onChangeTab"
    />
    <!-- <config-high-level-user-company
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompany"
      :dataConfig="configHighLevelUserCompany"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompany"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyDialog"
    /> -->
    <config-high-level-user-company-usd
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompanyUsd"
      :dataConfig="configHighLevelUserCompanyUsd"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompanyUsd"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyUsdDialog"
    />
    <config-high-level-user-company-kh
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompanyKh"
      :dataConfig="configHighLevelUserCompanyKh"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompanyKh"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyKhDialog"
    />
    <config-high-level-user-company-vn
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompanyVn"
      :dataConfig="configHighLevelUserCompanyVn"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompanyVn"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyVnDialog"
    />
    <config-high-level-user-company-rp
      :darkmode="darkmode"
      :dialog="dialogConfigHighLevelUserCompanyRp"
      :dataConfig="configHighLevelUserCompanyRp"
      :loading="loading"
      v-on:submit="onChangeConfigHighLevelUserCompanyRp"
      v-on:onCloseDialog="closeConfigHighLevelUserCompanyRpDialog"
    />
    <user-list1
      :darkmode="darkmode"
      :dialog="dialogListUser"
      :users="userChild"
      :data-request="data1"
      :userp="userp"
      v-on:getData1="getUserChild1"
      @onCloseDialog="dialogListUser = !dialogListUser"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
// import DialogDelete from "../../../../components/shared/notify/DialogDelete";
import DialogChangePassword from "../DialogChangePassword";
import configUserData from "../ConfigUserData";
import configSlotLimitData from "../ConfigSlotLimitData";
import ConfigHighLevelUser from "../ConfigHighLevelUser";
// import ConfigHighLevelUserCompany from "../ConfigHighLevelUserCompany";
import ConfigHighLevelUserCompanyUsd from "../ConfigHighLevelUserCompanyUsd";
import ConfigHighLevelUserCompanyKh from "../ConfigHighLevelUserCompanyKh";
import ConfigHighLevelUserCompanyVn from "../ConfigHighLevelUserCompanyVn";
import ConfigHighLevelUserCompanyRp from "../ConfigHighLevelUserCompanyRp";
import UserList1 from "../userList/Dialog1";

export default {
  components: {
    ConfigHighLevelUser,
    DialogChangePassword,
    // DialogDelete,
    UserList1,
    configUserData,
    configSlotLimitData,
    ConfigHighLevelUserCompanyUsd,
    ConfigHighLevelUserCompanyKh,
    ConfigHighLevelUserCompanyVn,
    ConfigHighLevelUserCompanyRp
    // ConfigHighLevelUserCompany,
  },
  props: [
    "darkmode",
    "item",
    "params",
    "dataDelete",
    "userPermission",
    "userp",
  ],
  data() {
    return {
      items: [
        { value:1, text: 'Limit Betting USD'},
        { value:2, text: 'Limit Betting KH'},
        { value:4, text: 'Limit Betting VN'},
        { value:5, text: 'Limit Betting RP'},
      ],
      validPassword: true,
      user: {
        _id: null,
        user_name: null,
        password: null,
      },
      dialogListUser: false,
      loading: false,
      dialogChangePassword: false,
      dialogBankUser: false,
      dialogConfigUserData: false,
      dialogConfigSlotLimitData: false,
      dialogConfigHighLevelUser: false,
      dialogDelete: false,
      userChild: [],
      data1: {
        descendingFormat: [true],
        descending: true,
        sortByFormat: ["_id"],
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: ["user_name", "balance", "account_info", "enable", "updatedAt"],
        search: "",
        parentId: null,
      },
      limitData: {
        descendingFormat: [true],
        sortByFormat: ["_id"],
        descending: true,
        sortBy: null,
        page: 1,
        rowsPerPage: 10,
        fields: [
          // "default_bet_a_fight",
          // "default_bet_win_a_day",
          "agent_bet_a_fight",
          "agent_bet_win_a_day",
        ],
        search: "",
      },
      limitConfig: {},
      configUserData: [],
      configSlotLimitData: {},
      limitHighLevelUser: [],
      configHighLevelUserCompany: {},
      configHighLevelUserCompanyUsd: [],
      configHighLevelUserCompanyKh: [],
      configHighLevelUserCompanyVn: [],
      configHighLevelUserCompanyRp: [],
      dialogConfigHighLevelUserCompany: false,
      dialogConfigHighLevelUserCompanyUsd: false,
      dialogConfigHighLevelUserCompanyKh: false,
      dialogConfigHighLevelUserCompanyVn: false,
      dialogConfigHighLevelUserCompanyRp: false,
      channel_type: 1
    };
  },
  watch: {
    configUserData: function () {},
    getConfigUserData: function () {},
  },
  computed: {
    ...mapGetters("$_userSubAgent", ["getCurrentUser"]),
    ...mapGetters("$_configUser", ["getConfigUserData","getConfigSlotLimitData"]),
  },
  methods: {
    async updateUser() {
      await this.fetchCurrentUser(this.item);
      await this.fetchAllRolesDependOnUser();
      this.fetchPopUp(true);
    },

    async addUpdateBankUser() {
      await this.fetchCurrentUser(this.item);
      await this.fetchCurrentBankUser(this.item._id);
      // await this.fetchAllRolesDependOnUser();
      this.fetchPopUpBankUser(true);
    },

    async upConfigUser() {
      let data = { currency_type: this.item.currency_type, _id: this.item._id };
      this.$request
        .post({
          url: "configUser/getConfigData",
          data: data,
        })
        .then((res) => {
          this.configUserData = res.data.data.object;
          this.channel_type = this.configUserData[0] ?this.configUserData[0].channel_type : 1;
          this.getLimitData(this.channel_type);
          this.dialogConfigUserData = true;
        });
    },

    async upConfigSlotLimit() {
      let data = { 
        _id: this.item._id ,
      };
      this.$request
        .post({
          url: "configUser/getSlotConfig",
          data: data,
        })
        .then((res) => {
          // console.log('res.data.data',res.data.data);
          this.configSlotLimitData = res.data.data.object;
          this.dialogConfigSlotLimitData = true;
        });
    },

    async onChangeConfigSlotLimit() {
      this.loading = true;
      let data = {
          user_id: this.item._id,
          total_bet_win_a_day_lotto: this.configSlotLimitData.total_bet_win_a_day_lotto,
          total_bet_win_a_day_yuki: this.configSlotLimitData.total_bet_win_a_day_yuki,
          total_bet_win_a_day_pp78: this.configSlotLimitData.total_bet_win_a_day_pp78,
          enable_lotto:this.configSlotLimitData.enable_lotto,
          enable_yuki:this.configSlotLimitData.enable_yuki,
          enable_pp78:this.configSlotLimitData.enable_pp78,
        };
        await this.updateConfigSlotLimit({ vm: this, data: data });
        this.loading = false;
        this.dialogConfigSlotLimitData = false;
    },

    async getAllLimitBetting() {
      let data = { currency_type: this.item.currency_type, _id: this.item._id };
      this.$request
        .post({
          url: "limitBetting/getAll",
          data: data,
        })
        .then((res) => {
          this.limitHighLevelUser = res.data.data.object;
          this.channel_type = this.limitHighLevelUser[0] ?this.limitHighLevelUser[0].channel_type : 1;
          this.getLimitData(this.channel_type);
          this.dialogConfigHighLevelUser = true;
        });
    },

    cancelDelete() {
      this.dialogDelete = false;
    },

    async deleteItem() {
      await this.deleteUser({
        vm: this,
        data: { deleteItems: [{ _id: this.item._id }] },
      });
      await this.fetchListUsers(this.params);
      this.dialogDelete = false;
    },
    onDialogConfigUserData() {
      this.dialogConfigUserData = true;
    },
    onDialogConfigSlotLimitData() {
      this.dialogConfigSlotLimitData = true;
    },
    onDialogChangePassword() {
      this.user = {
        _id: this.item._id,
        user_name: this.item.user_name,
        password: null,
      };
      this.dialogChangePassword = true;
    },

    async onChangePassword() {
      this.loading = true;
      if (this.user._id) {
        await this.changePassword({ vm: this, data: this.user });
        this.loading = false;
        this.dialogChangePassword = false;
      }
    },

    async onChangeConfigUser(payload) {
      this.loading = true;
      let dataLimit = this.configUserData.filter(channel => channel.channel_type == payload);
      let data;
      if (dataLimit.length > 0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        }
      }else{
        data = this.configUserData;
      }

      if (data._id) {
        await this.updateConfigUser({ vm: this, d: data });
        this.loading = false;
        // this.dialogConfigUserData = false;
      }
    },

    formatHighLevelUserData(payload) {
      let dataLimit = this.limitHighLevelUser.filter(channel => channel.channel_type == payload);
      
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.limitHighLevelUser;
      }
      
      data.currency_type = this.item.currency_type;
      return data;
    },
    async onChangeConfigHighLevelUser(payload) {
      this.loading = true;
      let formatData = this.formatHighLevelUserData(payload);
      if (formatData._id) {
        await this.updateLimitSetting({
          vm: this,
          data: formatData,
        });
        this.loading = false;
        //this.dialogConfigHighLevelUser = false;
      }
    },

    // async onChangeConfigHighLevelUserCompany() {
    //   this.loading = true;
    //   // លុយដូល្លា
    //   this.configHighLevelUserCompany.objectUsd[0].currency_type = 1;

    //   this.configHighLevelUserCompany.objectUsd[0].total_bet_a_fight = parseInt(
    //     this.configHighLevelUserCompany.objectUsd[0].total_bet_a_fight
    //   );
    //   this.configHighLevelUserCompany.objectUsd[0].total_bet_win_a_day =
    //     parseInt(
    //       this.configHighLevelUserCompany.objectUsd[0].total_bet_win_a_day
    //     );
    //   if (this.configHighLevelUserCompany.objectUsd[0]._id) {
    //     await this.updateLimitSetting({
    //       vm: this,
    //       data: this.configHighLevelUserCompany.objectUsd[0],
    //     });
    //   }
    //   // លុយ ខ្មែរ
    //   this.configHighLevelUserCompany.objectKh[0].currency_type = 2;

    //   this.configHighLevelUserCompany.objectKh[0].total_bet_a_fight = parseInt(
    //     this.configHighLevelUserCompany.objectKh[0].total_bet_a_fight
    //   );

    //   this.configHighLevelUserCompany.objectKh[0].total_bet_win_a_day =
    //     parseInt(
    //       this.configHighLevelUserCompany.objectKh[0].total_bet_win_a_day
    //     );
    //   if (this.configHighLevelUserCompany.objectKh[0]._id) {
    //     await this.updateLimitSetting({
    //       vm: this,
    //       data: this.configHighLevelUserCompany.objectKh[0],
    //     });
    //     this.loading = false;
    //     this.dialogConfigHighLevelUserCompany = false;
    //   }
    // },
    async onChangeConfigHighLevelUserCompanyUsd(payload) {
      this.loading = true;
      let dataLimit = this.configHighLevelUserCompanyUsd.objectUsd.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.configHighLevelUserCompanyUsd.objectUsd;
      }
      // លុយដូល្លា
      data.currency_type = 1;
      if (this.configHighLevelUserCompanyUsd.objectUsd[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: data,
        });
        this.loading = false;
      }
    },
    async onChangeConfigHighLevelUserCompanyKh(payload) {
      this.loading = true;
      let dataLimit = this.configHighLevelUserCompanyKh.objectKh.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.configHighLevelUserCompanyKh.objectKh;
      }
      
      // លុយ ខ្មែរ
      data.currency_type = 2;
      if (this.configHighLevelUserCompanyKh.objectKh[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: data,
        });
        this.loading = false;
      }
    },
    async onChangeConfigHighLevelUserCompanyVn(payload) {
      this.loading = true;
      let dataLimit = this.configHighLevelUserCompanyVn.objectVn.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.configHighLevelUserCompanyVn.objectVn;
      }
      
      // លុយ វៀតណាម
      data.currency_type = 4;
      if (this.configHighLevelUserCompanyVn.objectVn[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: data,
        });
        this.loading = false;
      }
    },
    async onChangeConfigHighLevelUserCompanyRp(payload) {
      this.loading = true;
      let dataLimit = this.configHighLevelUserCompanyRp.objectRp.filter(channel => channel.channel_type == payload);
      let data;
      if(dataLimit.length >0){
        data = {
          _id: dataLimit[0]._id,
          total_bet_a_fight: parseInt(dataLimit[0].total_bet_a_fight),
          total_bet_win_a_day: parseInt(dataLimit[0].total_bet_win_a_day),
          amount_min: parseInt(dataLimit[0].amount_min),
          amount_max: parseInt(dataLimit[0].amount_max),
          betting_frequency: parseInt(dataLimit[0].betting_frequency),
          enable: dataLimit[0].enable,
          channel_allow: dataLimit[0].channel_allow,
          channel_type: dataLimit[0].channel_type,
          user_id: dataLimit[0].user_id,
          currency_type: dataLimit[0].currency_type,
        };
      }else{
        data = this.configHighLevelUserCompanyRp.objectRp;
      }
      
      // លុយ វៀតណាម
      data.currency_type = 5;
      // console.log("data",data);
      if (this.configHighLevelUserCompanyRp.objectRp[0]._id) {
        await this.updateLimitSetting({
          vm: this,
          data: data,
        });
        this.loading = false;
      }
    },

    closeDialogChangePassword() {
      this.dialogChangePassword = false;
    },

    closeConfigUserDialog() {
      this.dialogConfigUserData = false;
    },
    closeConfigSlotLimitDialog() {
      this.dialogConfigSlotLimitData = false;
    },
    closeConfigHighLevelUserDialog() {
      this.dialogConfigHighLevelUser = false;
    },
    // closeConfigHighLevelUserCompanyDialog() {
    //   this.dialogConfigHighLevelUserCompany = false;
    // },
    closeConfigHighLevelUserCompanyUsdDialog() {
      this.dialogConfigHighLevelUserCompanyUsd = false;
    },
    closeConfigHighLevelUserCompanyKhDialog() {
      this.dialogConfigHighLevelUserCompanyKh = false;
    },
    closeConfigHighLevelUserCompanyVnDialog() {
      this.dialogConfigHighLevelUserCompanyVn = false;
    },
    closeConfigHighLevelUserCompanyRpDialog() {
      this.dialogConfigHighLevelUserCompanyRp = false;
    },
    getUserChild1() {
      this.data1.parentId = this.item._id;
      this.data1.sortByFormat.length < 1
        ? (this.data1.sortBy = "_id")
        : (this.data1.sortBy = this.data1.sortByFormat[0]);
      this.data1.descendingFormat.length > 0
        ? (this.data1.descending = this.data1.descendingFormat[0])
        : (this.data1.descending = true);
      this.$request
        .post({
          url: "users/getAllByUserId",
          data: this.data1,
        })
        .then((res) => {
          if (res.data.code) {
            this.dialogListUser = true;
            this.userChild = res.data.data;
          }
        });
    },
    // updateCompanyLimitBetting() {
    //   let data = { user_id: this.item._id };
    //   this.$request
    //     .post({
    //       url: "limitBetting/getCompanyLimitBetting",
    //       data: data,
    //     })
    //     .then((res) => {
    //       this.configHighLevelUserCompany = res.data.data;
    //       this.dialogConfigHighLevelUserCompany = true;
    //     });
    // },
    updateCompanyLimitBetting(value){
      if(value == 1){
        this.updateCompanyLimitBettingUsd();
      }else if(value == 2){
        this.updateCompanyLimitBettingKh();
      }else if(value == 4){
        this.updateCompanyLimitBettingVn();
      }else if(value == 5){
        this.updateCompanyLimitBettingRp();
      }
    },
    updateCompanyLimitBettingUsd() {
      let data = { user_id: this.item._id };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompanyUsd = res.data.data;
          
          this.dialogConfigHighLevelUserCompanyUsd = true;
        });
    },
    updateCompanyLimitBettingKh() {
      let data = { user_id: this.item._id };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompanyKh = res.data.data;
          this.dialogConfigHighLevelUserCompanyKh = true;
        });
    },
    updateCompanyLimitBettingVn() {
      let data = { user_id: this.item._id };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompanyVn = res.data.data;
          this.dialogConfigHighLevelUserCompanyVn = true;
        });
    },
    updateCompanyLimitBettingRp() {
      let data = { user_id: this.item._id };
      this.$request
        .post({
          url: "limitBetting/getCompanyLimitBetting",
          data: data,
        })
        .then((res) => {
          this.configHighLevelUserCompanyRp = res.data.data;
          this.dialogConfigHighLevelUserCompanyRp = true;
        });
    },
    async onChangeTab(channel_type){
      await this.getLimitData(channel_type);
    },
    async getLimitData(channel_type) {
      let data = {
        currency_type: this.item.currency_type,
        _id: this.item.parent_id,
        channel_type: channel_type
      };
      const response = await this.$request.post({
        url: "limitBetting/getParentLimitBettingByChannel",
        data: data,
      });
      this.limitConfig = response.data.data.object;
    },
    async getConfigData() {
      let data = {
        currency_type: this.item.currency_type,
        _id: this.item.parent_id,
      };
      const response = await this.$request.post({
        url: "config/getConfigData",
        data: data,
      });
      this.limitConfig = response.data.data.object;
    },
    ...mapMutations("$_configUser", ["UPDATE_CONFIG_USER_DATA","UPDATE_CONFIG_SLOT_LIMIT_DATA"]),
    ...mapActions("$_configUser", [
      "fetchGetAllConfigUser",
      "fetchGetConfigUserByUserId",
      "updateLimitSetting",
      "updateConfigSlotLimit",
    ]),

    ...mapActions("$_role", ["fetchAllRolesDependOnUser"]),
    ...mapActions("$_userSubAgent", [
      "fetchPopUp",
      "fetchCurrentUser",
      "fetchCurrentBankUser",
      "fetchPopUpBankUser",
      "deleteUser",
      "fetchListUsers",
      "changePassword",
      "updateConfigUser",
    ]),
  },
};
</script>
